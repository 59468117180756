import React from 'react';
import { FeatureContext } from 'core/constants/common';
import { APIRequestStatus } from 'core/constants/redux';
import { ObjModel } from 'core/models';

export interface ISettingsState {
  requestProcessing?: IRequestProcessing,
  productLines: FeatureContext[];
  dataSets: string[];
  data: {
    PlanInfo: {
        PlanName: string;
    };
    Configuration: IConfiguration;
  },
  showFeatureBlockedError: boolean
}

export interface ISettingsResponse {
  ProductLines: FeatureContext[];
  DataSets: string[];
  Data: {
    PlanInfo: {
        PlanName: string;
    };
    Configuration: IConfiguration;
  }
}
export interface IRequestProcessing {
  [SettingsStoreActions.SET_SETTINGS_REQUEST]: APIRequestStatus;

}
export interface IConfiguration{
      [ModuleName.ReportBuilder] : {
        Module: ModuleName,
        IsEnabled: boolean,
        Settings: {
          CustomReportsLimit: number,
          CustomReportsCreated: number,
          Filter: {
            FiltersLimit: number,
            StringFieldpartitionFilterLimit: number,
          },
          Grouping: {
            RowGroupingLimit: number,
            ColumnGroupingLimit: number,
          },
          Measures: {
            MeasuresLimit: number,
          },
        },
      },
      [ModuleName.DashboardBuilder]: {
        Module: ModuleName,
        IsEnabled: boolean,
        Settings: {
          CustomDashboardsLimit: number,
          CustomDashboardsCreated: number,
          Filter: {
            FiltersLimit:number,
            StringFieldpartitionFilterLimit: number,
          },
          Widgets: {
            WidgetsLimit: number,
          },
        },
      },
      [ModuleName.Scheduler]: {
        Module: ModuleName,
        IsEnabled: boolean,
        Settings: {
          SchedulesTenantLimit: number,
          SchedulesTenantCreated: number,
          SchedulesAdminUserLimit: number,
          SchedulesAdminUserCreated: number,
          SchedulesNonAdminUserLimit: number,
          SchedulesNonAdminUserCreated: number,
          RowLimitPerDay: number,
          AvailableSlots: any;
        },
      },
      [ModuleName.Export]: {
        Module: ModuleName,
        IsEnabled: boolean,
        Settings: {
          RowLimitPerDay: number,
          ExportAdminUserLimit: number,
          ExportUserCreated: number,
          ExportMarketingUserLimit: number,
          ExportSalesUserLimit: number,
          ExportSalesManagerLimit:number,
        },
      },
      [ModuleName.NamespaceBuilder]: {
        Module: ModuleName,
        IsEnabled: boolean,
        Settings: {
          CustomNamespaceLimit: number,
        },
      },
      [ModuleName.ExpressionBuilder]: {
        Module: ModuleName,
        IsEnabled: boolean,
        Settings:{
          CustomExpressionLimit: number,
          CustomExpressionCreated: number
        },
      },
      [ModuleName.DimensionBuilder]: {
          Module: ModuleName,
          IsEnabled: boolean,
          Settings:{
            CustomDimensionLimit: number,
            CustomDimensionCreated: number
          }
      },
      [ModuleName.ScheduleForOthers]: {
          Module: ModuleName,
          IsEnabled: boolean
      },
    }

export enum ModuleName{
  ReportBuilder = 'ReportBuilder',
  DashboardBuilder = 'DashboardBuilder',
  Scheduler = 'Scheduler',
  ScheduleForOthers = 'ScheduleForOthers',
  Export = 'Export',
  ExpressionBuilder = 'ExpressionBuilder',
  DimensionBuilder = 'DimensionBuilder',
  NamespaceBuilder = 'NamespaceBuilder'
}

export enum SettingsStoreActions {
  SET_SETTINGS_REQUEST = 'SET_SETTINGS_REQUEST',
  SET_SETTINGS_SUCCESS = 'SET_SETTINGS_SUCCESS',
  SET_SETTINGS_FAILURE = 'SET_SETTINGS_FAILURE',
  SET_SCHEDULER_LIMIT = 'SET_SCHEDULER_LIMIT',
  SET_EXPORT_LIMIT = 'SET_EXPORT_LIMIT',
  SET_SETTINGS_ERROR = 'SET_SETTINGS_ERROR',
  SET_SETTINGS='SET_SETTINGS'
}

export interface IFeatureGatingData {
  [key: string]: ObjModel.Obj;
}
