/* eslint-disable no-param-reassign */
import store from 'redux-v2/store';
import { DefaultFilterTypes, breakpoints } from 'components/feature/Report/ReportSidebar/common/constants';
import Cache from 'core/cache/cache';
import CacheItem from 'core/constants/cache';
import { FeatureList, routePaths } from 'core/constants/common';
import { DatePayloadFormat, IDateRangeTypes } from 'core/constants/date';
import {
  FilterType,
} from 'core/constants/filter';
import {
  DimensionStatus, QueryParamNames, ShowToastTypes,
} from 'core/constants/report';
import { ContextTypes, ListingSectionId, SectionItemId } from 'core/constants/report-listing';
import {
  ObjModel,
} from 'core/models';
import { Obj } from 'core/models/obj';
import {
  IColumn,
  IFilterConfig,
} from 'core/models/report-response';
import { DeviceType } from 'core/models/store';
import FeatureDefinitions from 'features/featureDefinitions';
import moment from 'moment-timezone';
import {
  IBuilder,
  IRawFilterMetaInfo,
  IReportConfig,
} from 'core/models/report-builder/report-builder';
import {
  filterBuilderToFilterConfig,
} from 'redux-v2/report-builder/report-builder-store.utils';
import { IFilterMetaInfo } from 'redux-v2/report-builder/report-builder-store.state';

export const clearAuthCacheItems = () => {
  Cache.removeItem(CacheItem.Auth);
  Cache.removeItem(CacheItem.AuthExpiry);
  Cache.removeItem(CacheItem.UserAttr);
  Cache.removeItem(CacheItem.OrgAttr);
};
export const isV1ReportsContext = () => {
  const { isV1Report } = store.getState().auth;
  return isV1Report;
};

export const isMarvinContext = () => {
  const { isMarvin } = store.getState().auth;
  return isMarvin;
};

export const isEmbeddedContext = () => {
  const { isEmbedded } = store.getState().auth;
  return isEmbedded;
};

export const getPendoState = () => {
  const selectedPage = store.getState().globalStore;
  return selectedPage;
};

export const redirctToLSQReportListingPage = () => {
  const lsqReportsHomePage = process.env.REACT_APP_LSQ_REPORTS_HOME;
  window.open(lsqReportsHomePage, '_self');
};

export const enum ToastNotificationTypes {
  CopyEmbedURLSuccess = 'CopyEmbedURLSuccess',
  RirectionURLGenerationError = 'RirectionURLGenerationError',
  SavedReportsError = 'SavedReportsError',
  ShowProfileCreationSuccess = 'ShowProfileCreationSuccess',
  ShowProfileCreationFailure = 'ShowProfileCreationFailure',
  ShowProfileUpdateSuccess = 'ShowProfileUpdateSuccess',
}

export enum EmptyScreenTypes {
  Pinned = 'pinned',
  PinnedForReport = 'pinnedForReport',
  NoPinnedDashboard = 'noPinnedDashboard',
  AllReports = 'allReports',
  SearchNoReports = 'SearchNoReports',
  SearchDashboards = 'SearchDashboards',
  AllDashboards = 'allDashboards',
  Tags = 'tags',
  HomeDashboard = 'homeDashboards',
  TagsDashboard = 'tagsDashboard',
  NoWidget = 'widget',
  BookmarkedReports = 'bookmarkedReports',
  ScheduledReports = 'scheduledReports',
  ScheduleHistory = 'scheduleHistory',
  ProfileReports = 'profileReports',
}

export const removeDuplicateObject = (
  array: any,
  uniqueProperty: any,
  additionalProperty?: any,
) => {
  const uniqueSet = new Set();
  const uniqueArray: any = [];

  array.forEach((object: any) => {
    const uniqueValue = object[uniqueProperty];
    const additionalValue = object[additionalProperty];
    const finalValue = additionalProperty
      ? `${uniqueValue}_${additionalValue || ''}`
      : uniqueValue;

    if (!uniqueSet.has(finalValue)) {
      uniqueSet.add(finalValue);
      uniqueArray.push(object);
    }
  });

  return uniqueArray;
};

export const NoOp = () => {
  // called when no operation has to be performed
};

export const filterDefaultValue: DefaultFilterTypes = {
  [FilterType.DateRange]: {
    Type: IDateRangeTypes.Absolute,
    Value: {
      From: moment().add(-7, 'days').startOf('day').format(DatePayloadFormat),
      To: moment().endOf('day').format(DatePayloadFormat),
    },
  },
  [FilterType.MultiTextSearch]: [],
  [FilterType.UserMultiSelect]: [],
  [FilterType.LSQMetadataMultiSelect]: {},
  [FilterType.GroupMultiSelect]: {},
};

export const clearPrefix = (input:string, delimiter: string) => {
  const splitInput = input.split(delimiter);
  if (splitInput.length > 1) {
    splitInput.shift();
    return splitInput.join(delimiter);
  }
  return input;
};

export const removeObjectWithSameId = (input: ObjModel.Obj[], key: string) => {
  const result = input.reduce((accumulator, current) => {
    const exists = accumulator.find((item: Obj) => item[key] === current[key]);
    if (!exists) {
      accumulator = accumulator.concat(current);
    }
    return accumulator;
  }, []);

  return result;
};

// Return the Feature Info base on the routePath
export const getFeatureFromPath = (path: routePaths): FeatureList => Object.keys(FeatureDefinitions)?.find((key) => FeatureDefinitions[key].route === path) as FeatureList;

// Returns the context in which application is rendered
export const getCurrentApplicationContext = () => {
  const { isEmbedded, isV1Report, isMarvin } = store.getState().auth;
  switch (true) {
    case isMarvin:
      return ContextTypes.Marvin;
    case isV1Report:
      return ContextTypes.V1;
    case isEmbedded:
      return ContextTypes.Embedded;
    default:
      return ContextTypes.Siera;
  }
};
export const getDeviceType = (width: number): DeviceType => {
  if (width <= breakpoints.MOBILE) {
    return DeviceType.MOBILE;
  }

  if (width <= breakpoints.TABLET) {
    return DeviceType.TABLET;
  }
  return DeviceType.DESKTOP;
};

export const generateNumberArray = (length: number) => [...new Array(length).keys()];

// Returns the list of all the mapped metafields
// @columns - Array of all applied columns
// @reportData -  report Data for the report.
export const getMappedMetaFields = (columns: IColumn[], reportData:ObjModel.Obj[]) => {
  let referrerCol:IColumn;
  let referredCol:IColumn;
  let mappedMetaFieldsObj:object;
  columns.forEach((col) => {
    if (col.Props.Dimension?.Applied !== DimensionStatus.NotApplied
      && (col.Props.ReferTo !== null && col.Props.ReferTo !== undefined && (col.Props.ReferTo && col.Props.ReferTo !== ''))) {
      referrerCol = col;
      referredCol = columns.find((column) => column.Name === referrerCol?.Props.ReferTo);
      if (referredCol && referrerCol && reportData) {
        const mappedMetaFieldObj = getMappedMetaField(reportData, referrerCol, referredCol);
        mappedMetaFieldsObj = {
          ...mappedMetaFieldsObj,
          ...mappedMetaFieldObj,
        };
      }
    }
  });

  return mappedMetaFieldsObj;
};

// Returns the applied mapped Metafield
// @referrerCol - Applied parent dimension.
// @fetchdata -  report Data for the report.
// @referrerCol - Applied dimension to which parent dimension linked
export const getMappedMetaField = (fetchdata: ObjModel.Obj[], referrerCol:IColumn, referredCol:IColumn) : object => {
  const referrerColName = referrerCol?.BuilderConfig?.IsDynamicField ? referrerCol?.BuilderConfig?.Alias : referrerCol?.Name;
  let mappedObj:any;
  fetchdata.forEach((data) => {
    const keyString = data[referrerCol?.Name];
    mappedObj = {
      ...mappedObj,
      [keyString]: data[referredCol?.Name],
    };
  });
  return {
    [referrerColName]: mappedObj,
  };
};

// Returns filter config based on the filter group of report raw  config
// @state - Report builder state
export const getFilterConfigFromTemplate = (reportConfig: IReportConfig, filterMetaInfo?: IFilterMetaInfo[], rawFilterMetaInfo?: IRawFilterMetaInfo) => {
  const { Builder } = reportConfig;
  const filterMetaInformation = filterMetaInfo;
  const partitionFilters = rawFilterMetaInfo?.partitionColumnFilterIds;
  const selectedFilter: IFilterConfig[] = [];

  Builder.forEach((builderInfo: IBuilder) => {
    const filterConfig = filterBuilderToFilterConfig(
      builderInfo.Filter,
      filterMetaInformation,
      partitionFilters,
    );
    selectedFilter.push(...filterConfig);
  });
  return selectedFilter;
};

// Generates the report URL on Save and SaveAs action based on isMarvin and IsEmbedded context
export const getSaveSaveAsURL = (isMarvin: boolean, isEmbedded: boolean, reportId: string) => {
  const params = new URLSearchParams(window.location.search);
  const referral = params.get(QueryParamNames.Referral) || `${ListingSectionId.Report}/${SectionItemId.PinnedReports}`;
  let reportUrl = `${window.location.origin}/report/${reportId}?referral=${referral}&${QueryParamNames.ShowToast}=${ShowToastTypes.SAVEAS}`;
  if (isMarvin) {
    reportUrl += '&isMarvin=true';
  }
  if (isEmbedded) {
    reportUrl += '&context=Embedded';
  }

  return reportUrl;
};

export const findValueByKey = (object: Object, key: string) => {
  let result: any;

  function search(obj: ObjModel.Obj) {
    if (obj !== null && typeof obj === 'object') {
      if (Object.hasOwnProperty.call(obj, key)) {
        result = obj[key];
        return;
      }
      Object.keys(obj).forEach((k) => {
        if (typeof obj[k] === 'object' && obj[k] !== null) {
          search(obj[k]);
        }
      });
    }
  }

  search(object);
  return result;
};
