import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportFetchCriteria, ReportService } from 'services/report.service';
import {
  IAutoDrilldownV2Data,
  IDataRefreshDetailsLoadRequestPayload, IFilterLoadRequestPayload, ILoadMoreDropdownOptionsPayload, IPaginationChangePayload, IReportConfigSaveRequestPayload, ISearchDropdownOptionsPayload, ISortingChangePayload,
} from 'core/models/report-redux';
import { AccessType, DimensionStatus, statusCodes } from 'core/constants/report';
import ReportBuilderService from 'services/report-builder.service';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import { IGlobalState } from 'core/models/redux';
import { getDrilldownReportDataLoad, getReportDataLoadPayload, getUpdatedReportConfig } from 'core/utils/report.util';
import { IColumn, IReportData } from 'core/models/report-response';
import { isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import cloneDeep from 'lodash/cloneDeep';
import { IVariables } from 'core/models/store';
import { isEmpty } from 'lodash';
import { FeatureContext, PageContext } from 'core/constants/common';
import SieraResponse from 'core/models/siera-response';
import { setSettingsError } from 'redux-v2/settings-store/settings-store.reducer';
import { IFetchDrilldownPayload, ReportActionTypes } from './report-store.state';
import { getStateOnDrilldownSortColumn, getStateOnPaginationChange } from './report-store.utils';
import {
  drilldownSortingChange, getTempAutodrilldownStateId, loadAutodrilldown, reportAutodrilldown,
  autodrilldownLoadFailure, autodrilldownLoadSuccess,
  reportLoadMoreOptions, reportSearchDropdownOptions, reportPaginationChange,
} from './report-store.reducer';

export const getReportConfig = createAsyncThunk(ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST,
  async (params: { reportId: string, sourceId: string, variables: IVariables }) => new ReportService().getReportConfig({ reportId: params.reportId, sourceId: params.sourceId }));

export const getFiltersLoad = createAsyncThunk(ReportActionTypes.FILTER_LOAD_REQUEST,
  async (params: IFilterLoadRequestPayload, { getState }) => {
    const { auth, reportStoreV2 } = getState() as IGlobalState;
    const { variables } = auth;
    const { filterId, filterValue, isLazyLoad } = params;
    const {
      filterConfig, appliedFilters, isResetClicked, reportConfig,
    } = reportStoreV2;
    let featureContextInfo = reportConfig?.FeatureContext;
    if (Object.prototype.hasOwnProperty.call(reportConfig, 'FeatureContext') && !reportConfig?.FeatureContext) {
      featureContextInfo = FeatureContext.Sales;
    }
    const filter = filterConfig.find((item) => item.ID === filterId);
    const value = filter.LinkedTo
      ? appliedFilters[filter.LinkedTo].FilterResponse
      : null;
    const filterPayload = new FilterFetchUtility(variables).getUserAdditionalFilterPayload(
      appliedFilters,
      filterConfig,
      isResetClicked,
    );
    return new FilterFetchUtility(variables).GetSingleFilterData(filter,
      value,
      isLazyLoad
        ? filterValue
        : appliedFilters[filterId].FilterResponse,
      appliedFilters[filterId],
      filterPayload, featureContextInfo);
  });

export const getReportData = createAsyncThunk(ReportActionTypes.REPORT_DATA_LOAD_REQUEST,
  async (params: string, { getState }) => {
    const { reportStoreV2 } = getState() as IGlobalState;
    const {
      filterConfig, reportConfig, activeFilters, sort, page, columns, activeDimensions, allMeasures, dynamicDimensions, reportId,
    } = reportStoreV2;
    const data = getReportDataLoadPayload(filterConfig, activeFilters, reportId, sort, page, columns, activeDimensions, allMeasures, dynamicDimensions);
    data.FeatureContext = reportConfig.FeatureContext || FeatureContext.Sales;
    return new ReportService().getReportData(data);
  });
export const getRawConfig = createAsyncThunk(ReportActionTypes.GET_RAW_CONFIG_LOAD_REQUEST,
  async (params:{reportId:string}) => new ReportService().getRawConfig(params));
export const getDynamicDimensions = createAsyncThunk(ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST,
  async (params:{selectedMeasure?: string, measureFieldAlias?: string}, { getState }) => {
    const { reportStoreV2 } = getState() as IGlobalState;
    const { selectedMeasure, measureFieldAlias } = params;
    if (selectedMeasure) {
      return new ReportService().getDrilldownFields(reportStoreV2.reportId, selectedMeasure, measureFieldAlias);
    }
    return new ReportService().getDynamicDimensions(reportStoreV2.reportId);
  });
export const getDynamicFilters = createAsyncThunk(ReportActionTypes.DYNAMIC_FILTERS_LOAD_REQUEST,
  async (reportId:string) => new ReportService().getDynamicFilters(reportId));
export const getDynamicMeasures = createAsyncThunk(ReportActionTypes.REPORT_DYNAMIC_MEASURES_LOAD_REQUEST,
  async (reportId: string) => new ReportService().getDynamicMeasures(reportId));
export const getFetchDrilldownReport = createAsyncThunk(ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST,
  async (params: IFetchDrilldownPayload, { getState, dispatch }) => {
    const {
      selectedMeasure,
      measureFieldAlias,
      page,
      sort,
    } = params;
    dispatch(loadAutodrilldown());
    const { reportStoreV2 } = getState() as IGlobalState;
    const newState = cloneDeep(reportStoreV2);

    newState.autodrilldownData = { ...newState.autodrilldownData, sort: sort || newState.autodrilldownData.sort, page: page || newState.autodrilldownData.page };
    const {
      filterConfig, reportConfig, activeFilters, reportId, columns, activeDimensions, allMeasures, autodrilldownData, dynamicDimensions,
    } = newState;
    const fetchDrillDownRequest = getDrilldownReportDataLoad(reportId, autodrilldownData, filterConfig, columns, activeDimensions, allMeasures, activeFilters, dynamicDimensions);
    fetchDrillDownRequest.FeatureContext = reportConfig?.FeatureContext || FeatureContext.Sales;

    return new ReportService().getFetchDrilldownReport(fetchDrillDownRequest, { isRefresh: params?.isRefresh })
      .then((res: IReportData) => {
        if (isEmpty(reportStoreV2?.autodrilldownData?.drilldownFields)) {
          dispatch(getDynamicDimensions({ selectedMeasure, measureFieldAlias }));
        }
        dispatch(autodrilldownLoadSuccess(res));
      })
      .catch(() => dispatch(autodrilldownLoadFailure()));
  });
export const getTempDrilldownState = createAsyncThunk(ReportActionTypes.AUTODRILLDOWN_V2,
  async (stateId: string, { getState, dispatch }) => {
    const { reportStoreV2 } = getState() as IGlobalState;
    new ReportService().getDrilldownState(stateId).then((res: IAutoDrilldownV2Data) => {
      dispatch(reportAutodrilldown({
        selectedMeasure: res.SelectedMeasure,
        selectedRowData: res.SelectedRow,
        measureFieldAlias: res.MeasureFieldAlias,
      }));
      if (reportStoreV2?.reportConfig?.Settings?.IsNormalised) {
        dispatch(getDataRefreshStatus({ reportId: reportStoreV2.reportId }));
      } else {
        dispatch(getDataRefreshDetails({ reportId: reportStoreV2.reportId }));
      }

      dispatch(getTempAutodrilldownStateId(res));
      dispatch(getFetchDrilldownReportV2(res));
    }).catch();
  });
export const getFetchDrilldownReportV2 = createAsyncThunk(ReportActionTypes.AUTODRILLDOWN_REPORTV2_DATA_LOAD_REQUEST,
  async (params: IAutoDrilldownV2Data, { getState, dispatch }) => {
    const { reportStoreV2 } = getState() as IGlobalState;
    const {
      autodrilldownData, reportConfig,
    } = reportStoreV2;
    const drilldownFields : IColumn[] = isValidArrayAndNotEmpty(autodrilldownData.drilldownFields) ? autodrilldownData.drilldownFields.filter(
      (dim) => dim.Props && dim.Props.Dimension && dim.Props.Dimension.Applied === DimensionStatus.Applied,
    ) : null;
    const data = {
      ...params,
      DrilldownColumns: drilldownFields,
      Page: autodrilldownData.page,
      Sort: autodrilldownData.sort,
      FeatureContext: reportConfig?.FeatureContext || FeatureContext.Sales,
    };

    return new ReportService().getFetchDrilldownReportV2(data).then((res) => {
      if (isEmpty(reportStoreV2.autodrilldownData.drilldownFields)) {
        dispatch(getDynamicDimensions({
          selectedMeasure: params.SelectedMeasure,
          measureFieldAlias: params.MeasureFieldAlias,
        }));
      }
      dispatch(autodrilldownLoadSuccess(res));
    }).catch();
  });
export const getExportLists = createAsyncThunk(ReportActionTypes.EXPORT_LIST_LOAD_REQUEST,
  async () => new ReportService().getExportListData());
export const getExportUsageStatus = createAsyncThunk(ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST,
  async () => new ReportService().getExportUsageStatus());
export const getExportListDataByIds = createAsyncThunk(ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST,
  async (exportIds: string[]) => new ReportService().getExportListDataByIds(exportIds));
export const getRetryExportDatagetRetryExportData = createAsyncThunk(ReportActionTypes.RETRY_EXPORT_REQUEST,
  async (exportId: string) => new ReportService().getRetryExportData({ ExportId: exportId }));

export const getDataRefreshDetails = createAsyncThunk(ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST,
  async (params: IDataRefreshDetailsLoadRequestPayload) => new ReportService().getDataRefreshDetails({ reportId: params.reportId }));

export const getDataRefreshStatus = createAsyncThunk(ReportActionTypes.DATA_REFRESH_STATUS_LOAD_REQUEST,
  async (params: IDataRefreshDetailsLoadRequestPayload) => new ReportService().getDataRefreshStatus({ reportId: params.reportId }));

export const accesscontrol = createAsyncThunk(ReportActionTypes.ACCESS_CONTROL_REQUEST,
  async (params: {reportId: string, type: AccessType, isPolling?: boolean}) => new ReportService().accesscontrol(params.reportId, params.type));
export const deleteReport = createAsyncThunk(ReportActionTypes.DELETE_REPORT,
  async (reportId: string) => new ReportService().deleteReport(reportId));
export const saveReportConfig = createAsyncThunk<Object, IReportConfigSaveRequestPayload, { rejectValue: SieraResponse<string>}
  >(ReportActionTypes.SAVE_REPORT_CONFIG_REQUEST,
    async (params: IReportConfigSaveRequestPayload, { getState, rejectWithValue, dispatch }) => {
      try {
        const { reportStoreV2 } = getState() as IGlobalState;
        const { isSaveAs } = params as IReportConfigSaveRequestPayload;
        const updatedReportConfig = getUpdatedReportConfig(cloneDeep(reportStoreV2), isSaveAs);
        const result = await new ReportBuilderService().saveReportConfig(updatedReportConfig);
        return result;
      } catch (err) {
        if ((err as Response)?.status === statusCodes.Unprocessable) dispatch(setSettingsError(true));
        return rejectWithValue({
          status: (err as Response)?.status || 500,
          data: (err as Error).message || 'Something went wrong',
        });
      }
    });
export const getExportData = createAsyncThunk(ReportActionTypes.EXPORT_DATA_LOAD_REQUEST, async (fileType: string, { getState }) => {
  const { reportStoreV2 } = getState() as IGlobalState;
  const {
    filterConfig, activeFilters, reportId, sort, page, columns, activeDimensions, allMeasures, autodrilldownData, dynamicDimensions,
  } = reportStoreV2;
  let data:ReportFetchCriteria;
  if (!autodrilldownData?.reportData) {
    data = getReportDataLoadPayload(filterConfig, activeFilters, reportId, sort, page, columns, activeDimensions, allMeasures, dynamicDimensions);
  } else {
    data = getDrilldownReportDataLoad(reportId, autodrilldownData, filterConfig, columns, activeDimensions, allMeasures, activeFilters, dynamicDimensions);
  }

  return new ReportService().getExportData(data, fileType);
});

export const getExportDataV2 = createAsyncThunk(ReportActionTypes.DASHBOARD_REDIRECTED_EXPORT_DATA_LOAD_REQUEST, async (fileType: string, { getState }) => {
  const { reportStoreV2 } = getState() as IGlobalState;
  const {
    autoDrilldownDataV2, autodrilldownData,
  } = reportStoreV2;
  const drilldownFields : IColumn[] = autodrilldownData.drilldownFields.filter(
    (dim) => dim.Props && dim.Props.Dimension && dim.Props.Dimension.Applied === DimensionStatus.Applied,
  );
  const data = {
    ...autoDrilldownDataV2,
    DrilldownColumns: drilldownFields,
    Page: autodrilldownData.page,
  };

  return new ReportService().getExportDataV2(data, fileType);
});

export const loadFilterMoreOptions = createAsyncThunk(ReportActionTypes.LOAD_MORE_DROPDOWN_OPTIONS, async (params: ILoadMoreDropdownOptionsPayload, { getState, dispatch }) => {
  dispatch(reportLoadMoreOptions(params));
  const { auth: { variables } } = getState() as IGlobalState;
  dispatch(getFiltersLoad({
    filterId: params.filterId, filterValue: params.filterValue, variables, isLazyLoad: true,
  }));
});

export const searchFilterOptions = createAsyncThunk(ReportActionTypes.SEARCH_DROPDOWN_OPTIONS, async (params: ISearchDropdownOptionsPayload, { getState, dispatch }) => {
  dispatch(reportSearchDropdownOptions(params));
  const { auth: { variables } } = getState() as IGlobalState;
  dispatch(getFiltersLoad({
    filterId: params.filterId, filterValue: params.filterValue, variables, isLazyLoad: true,
  }));
});

export const autoDrilldownPaginationChange = createAsyncThunk(ReportActionTypes.PAGINATION_CHANGE, async (params: IPaginationChangePayload, { getState, dispatch }) => {
  const { reportStoreV2 } = getState() as IGlobalState;
  dispatch(reportPaginationChange(params));
  const {
    paginationKey, paginationValue, isAutodrilldown,
  } = params;
  const newState = getStateOnPaginationChange(
    cloneDeep(reportStoreV2),
    paginationKey,
    paginationValue,
    isAutodrilldown,
  );
  const { autodrilldownData, autoDrilldownDataV2 } = newState;
  if (autoDrilldownDataV2?.DataSourceId) {
    dispatch(getFetchDrilldownReportV2({ ...autoDrilldownDataV2, Page: autodrilldownData?.page }));
  } else {
    dispatch(getFetchDrilldownReport({ ...autoDrilldownDataV2, page: autodrilldownData?.page }));
  }
});

export const autoDrilldownSortingChange = createAsyncThunk(ReportActionTypes.DRILLDOWN_SORTING_CHANGE, async (params: ISortingChangePayload, { getState, dispatch }) => {
  const { reportStoreV2 } = getState() as IGlobalState;
  dispatch(drilldownSortingChange(params));
  const {
    columnType,
    columnName,
    order,
    multiSort,
  } = params;
  const newState = getStateOnDrilldownSortColumn(
    cloneDeep(reportStoreV2),
    columnType,
    columnName,
    order,
    multiSort,
  );
  const { autoDrilldownDataV2, autodrilldownData } = newState;
  if (autoDrilldownDataV2?.DataSourceId) {
    dispatch(getFetchDrilldownReportV2({ ...autoDrilldownDataV2, Sort: autodrilldownData?.sort }));
  } else {
    dispatch(getFetchDrilldownReport({ ...autodrilldownData, sort: autodrilldownData?.sort }));
  }
});
