import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'core/constants/redux';
import {
  ISettingsResponse, ISettingsState, SettingsStoreActions,
} from './settings-store-state';
import InitialSettingsState from './settings-store-initial-state';
import { updatePlanSettings } from './settings-store.requests';

const settingsStoreSlice = createSlice({
  name: 'settingsStore',
  initialState: InitialSettingsState(),
  reducers: {
    [SettingsStoreActions.SET_SETTINGS_ERROR]: (state: ISettingsState, action: PayloadAction<boolean>) => {
      state.showFeatureBlockedError = action.payload;
    },
    [SettingsStoreActions.SET_SETTINGS]: (state: ISettingsState, action: PayloadAction<ISettingsResponse>) => {
      state.data = action.payload.Data;
      state.productLines = action.payload.ProductLines;
      state.dataSets = action.payload.DataSets;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePlanSettings.pending, (state: ISettingsState) => {
      state.requestProcessing[SettingsStoreActions.SET_SETTINGS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(updatePlanSettings.fulfilled, (state: ISettingsState, action: PayloadAction<ISettingsResponse>) => {
      state.data = action.payload.Data;
      state.productLines = action.payload.ProductLines;
      state.dataSets = action.payload.DataSets;
    });
    builder.addCase(updatePlanSettings.rejected, (state: ISettingsState) => {
      state.requestProcessing[SettingsStoreActions.SET_SETTINGS_REQUEST] = APIRequestStatus.Failure;
    });
  },
});

export const {
  [SettingsStoreActions.SET_SETTINGS_ERROR]: setSettingsError,
  [SettingsStoreActions.SET_SETTINGS]: setSettingsResponse,

} = settingsStoreSlice.actions;

export default settingsStoreSlice;
